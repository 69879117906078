import { React, useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import ContactImage from "../../assets/contact/thepacheli-reachout-contactus-yourworldchanging-iswait_35.jpg";
import validateForm from "../../components/Contact/validator";
import { Container } from "../../globalStyles";
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Contact.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FormButton,
  FormColumn,
  FormInput,
  FormInputRow,
  FormLabel,
  FormMessage,
  FormRow,
  FormSection,
  FormTitle,
  FormWrapper,
} from "./FormStyle";
import SEO from "../SEO/Seo";

export default function Contact() {
  const form = useRef();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");
  //const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const resultError = validateForm({ name, email, phone, message });

    //sending email
    emailjs
      .sendForm("service_hgd3jle", "template_evh6f68", form.current, {
        publicKey: "Bs982XTZMxlM3CQ9d",
      })
      .then(
        (result) => {
          console.log(result);
          //setSubmitted(true);
          setLoading(false);
          toast.success(
            "Thank you. I will get back to you as soon as possible."
          );
        },
        (error) => {
          setLoading(false);
          console.error(error);
          setLoading(false);
          toast.success("Oops, something went wrong. Please try again.");
        }
      );

    if (resultError !== null) {
      setError(resultError);
      setLoading(false);
      return;
    }

    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setError(null);
    setSuccess(true);
    setSubmitMessage(
      "Thank you for contacting ThePacheli! We've received your message and will be in touch soon to discuss your needs further."
    );
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const formData = [
    {
      label: "Full Name",
      name: "from_user_name",
      value: name,
      onChange: (e) => setName(e.target.value),
      type: "text",
    },
    {
      label: "Email Address",
      name: "user_emailAddress",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      type: "email",
    },
    {
      label: "Phone Number",
      name: "user_phoneNumber",
      value: phone,
      onChange: (e) => setPhone(e.target.value),
      type: "text",
    },
    {
      label: "Message",
      value: message,
      name: "user_feedback",
      onChange: (e) => setMessage(e.target.value),
      type: "text",
    },
  ];

  return (
    <>
      <div>
        <SEO
          title="Software Development | Technology Solutions | IT Service Provider | Thepacheli"
          description="Empower your business with cutting-edge software, web development, and mobile app solutions from Thepacheli. Partner with our experts for innovation, growth, and success.."
        />
        <section id="contact">
          <div className="container">
            <div className="contact_wrapper">
              <div className="contact_col">
                <div
                  data-aos="fade-right"
                  data-aos-offset="500"
                  data-aos-duration="800"
                  data-aos-easing="ease-in-sine"
                  className="contact_image"
                >
                  <img src={ContactImage} alt="Contact" />
                </div>
              </div>

              <div className="contact_col">
                <FormSection
                  data-aos="fade-left"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset="1000"
                  data-aos-duration="1000"
                >
                  <Container>
                    <ToastContainer position="top-right" />
                    <div className={loading ? "loading-overlay" : ""}>
                      <FormRow>
                        <FormColumn>
                          <FormTitle>Get in touch</FormTitle>
                          <FormWrapper onSubmit={handleSubmit} ref={form}>
                            {formData.map((el, index) => (
                              <FormInputRow key={index}>
                                <FormLabel>{el.label}</FormLabel>
                                <FormInput
                                  type={el.type}
                                  placeholder={`Enter your ${el.label.toLocaleLowerCase()}`}
                                  value={el.value}
                                  name={el.name}
                                  autoComplete="new-password"
                                  onChange={el.onChange}
                                  className="input_wrapper"
                                />
                              </FormInputRow>
                            ))}
                            <FormButton type="submit">Submit</FormButton>
                            <p>{submitMessage}</p>
                          </FormWrapper>
                          {error && (
                            <FormMessage
                              variants={messageVariants}
                              initial="hidden"
                              className="input_wrapper"
                              animate="animate"
                              error
                            >
                              {error}
                            </FormMessage>
                          )}
                          {success && (
                            <FormMessage
                              variants={messageVariants}
                              initial="hidden"
                              className="input_wrapper"
                              animate="animate"
                            >
                              {success}
                            </FormMessage>
                          )}
                        </FormColumn>
                      </FormRow>
                    </div>
                    {loading && (
                      <div className="loading-modal">
                        <ClipLoader
                          color="#f86c6b"
                          size={150}
                          loading={loading}
                        />
                      </div>
                    )}
                  </Container>
                </FormSection>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
