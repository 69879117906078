import React, { useEffect, useState } from "react";
import BlogImage from "../../assets/blog/thepacheli-teamstrategy-module-inovation-takeworldtothestorm-24.jpg";
import "./Blog.css";
import SEO from "../SEO/Seo";
import AOS from "aos";
import "aos/dist/aos.css";
import Modal from "react-modal";
import MyQuotation from "../Quotation/Index";

export default function Blog() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  const openModal = () => {
    setModalIsOpen(true);
  };
  return (
    <>
      <div>
        <SEO
          title="Software Development | Technology Solutions | IT Service Provider | Thepacheli"
          description="Empower your business with cutting-edge software, web development, and mobile app solutions from Thepacheli. Partner with our experts for innovation, growth, and success.."
        />
        <section id="blog">
          <div className="container">
            <div className="blog_wrapper">
              <div data-aos="zoom-in-right" className="blog_col">
                <h3>
                  We Craft, Modernize, & Maintain Software Solutions that Drive
                  Your Success
                </h3>
                <p>
                  We are passionate about empowering businesses through
                  innovative software solutions. We see ourselves as your
                  digital partners, crafting bespoke solutions tailored to your
                  unique needs and goals.
                  <br />
                  We don't just build, we believe in fostering long-term
                  relationships, ensuring your existing systems are always
                  up-to-date and operating smoothly. From initial brainstorming
                  to seamless implementation, we're here to guide you every step
                  of the way. Let's explore how we can help you unlock your full
                  digital potential!
                </p>
                <div className="btn_wrapper">
                  <button className="btn" onClick={openModal}>
                    Learn more
                  </button>
                </div>
              </div>
              <div className="blog_col">
                <div data-aos="zoom-in-up" className="blog_image">
                  <img src={BlogImage} alt="Blog" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        ariaHideApp={false}
      >
        <button
          onClick={() => setModalIsOpen(false)}
          style={{ position: "absolute", right: 15, top: 15 }}
          aria-label="Close Modal"
        >
          X
        </button>
        <MyQuotation />
      </Modal>
    </>
  );
}
