import {
  faChartArea,
  faChartLine,
  faLightbulb,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useRef, useState } from "react";
import BannerImage from "../../assets/banner/banner-img.jpg";
import "./Home.css";
import SEO from "../SEO/Seo";

export default function Home() {
  // Icons
  const faChartIcon = <FontAwesomeIcon icon={faChartLine} />;
  const faShieldIcon = <FontAwesomeIcon icon={faShieldHalved} />;
  const faChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />;
  const faLightbulbAreaIcon = <FontAwesomeIcon icon={faLightbulb} />;

  // Titles for slideshow
  const titles = [
    {
      titles_lines: "SUCCESS",
      titles_head: "WE PROMOTE CLIENT",
      titles_body:
        "Prioritize client satisfaction and success by understanding their unique needs, delivering tailored solutions, and providing exceptional customer service and support",
    },
    {
      titles_lines: "INNOVATION",
      titles_head: "TECHNOLOGY",
      titles_body:
        "Push the boundaries of technology by constantly exploring emerging trends, investing in research and development, and adopting cutting-edge tools and frameworks",
    },
    {
      titles_lines: "EXCELLENCE",
      titles_head: "RELENTLESS",
      titles_body:
        "We settle for nothing less than exceptional in everything we do, from conception to execution, exceeding expectations at every turn",
    },
  ];

  // Delay between slide transitions
  const delay = 5000;

  // State for current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // Timeout reference for automatic slide change
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      const nextSlideIndex = getNextSlideIndex(
        currentSlideIndex,
        titles.length
      );
      setCurrentSlideIndex(nextSlideIndex);
    }, delay);
    return () => resetTimeout();
  }, [currentSlideIndex, titles.length]);

  const getNextSlideIndex = (currentIndex, titlesLength) =>
    currentIndex === titlesLength - 1 ? 0 : currentIndex + 1;

  // Function to handle manual navigation (optional)
  const handlePrevSlide = () => {
    const newIndex =
      currentSlideIndex === 0 ? titles.length - 1 : currentSlideIndex - 1;
    setCurrentSlideIndex(newIndex);
  };

  const handleNextSlide = () => {
    const nextSlideIndex = getNextSlideIndex(currentSlideIndex, titles.length);
    setCurrentSlideIndex(nextSlideIndex);
  };

  return (
    <>
      <div>
        <SEO
          title="Software Development | Technology Solutions | IT Service Provider | Thepacheli"
          description="Empower your business with cutting-edge software, web development, and mobile app solutions from Thepacheli. Partner with our experts for innovation, growth, and success.."
        />
        <section id="home">
          <div className="banner_image"></div>
          <div className="container">
            <div className="banner_outer">
              <div className="col slideshow">
                <div
                  className="slideshowSlider"
                  style={{
                    transform: `translate3d(${
                      -currentSlideIndex * 100
                    }%, 0, 0)`,
                  }}
                >
                  {titles.map((val, index) => (
                    <div key={index} className="slide">
                      <h3 className="title">
                        {val.titles_head} <span>{val.titles_lines}</span>
                      </h3>
                      <p className="text-white-vr">{val.titles_body}.</p>
                      <div className="btn_wrapper">
                        <a className="btn" href="/">
                          Get Started
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
                {/* Add navigation controls (optional) */}
                <button className="slide-arrow prev" onClick={handlePrevSlide}>
                  &#8592;
                </button>
                <button className="slide-arrow next" onClick={handleNextSlide}>
                  &#8594;
                </button>
              </div>
              <div className="col">
                <div className="sub_banner_image">
                  <img src={BannerImage} alt="Banner_image" />
                </div>
                <div className="banner_style_1">
                  {faChartIcon}
                  <h4>Software solutions</h4>
                </div>
                <div className="banner_style_1 banner_style_3">
                  {faShieldIcon}
                  <h4>Excellence & Integrity</h4>
                </div>
                <div className="banner_style_1 banner_style_2">
                  {faChartAreaIcon}
                  <h4>Strategy</h4>
                </div>
                <div className="banner_style_1 banner_style_4">
                  {faLightbulbAreaIcon}
                  <h4>Innovation & Transformation</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
