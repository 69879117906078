import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import LogoNotSlides from "../../assets/logo/thepacheli-main-logo-bg-grayed.png";
import LogoSlides from "../../assets/logo/thepacheli-logoSlides-bg-grayed-dm.png";
import "./Navbar.css";

export default function Navbar() {
  // Mobile Menu State
  const [sidenav, setSidenav] = useState(false);

  // Desktop Fixed Menu
  const [sticky, setSticky] = useState(false);

  // Mobile Icon
  const menuIcon = <FontAwesomeIcon icon={faBars} />;

  // SideNav
  const sidenavShow = () => {
    setSidenav(!sidenav);
  };

  // Scroll Fixed Navbar
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <>
      <header id="site_header" className={`${sticky ? "sticky" : ""}`}>
        <div className="container">
          <nav className="navbar" id="Navbar">
            <div className="navbar_brand">
              {sticky ? (
                <div className="">
                  <a href="/">
                    <img src={LogoSlides} alt="Thepacheli_Logo_virtual88520" />
                  </a>
                </div>
              ) : (
                <div className="">
                  <a href="/">
                    <img
                      className="logo-renou"
                      src={LogoNotSlides}
                      alt="Thepacheli_Logo_virtu5690520"
                    />
                  </a>
                </div>
              )}
            </div>
            <div className="navbar_toggler" onClick={sidenavShow}>
              {menuIcon}
            </div>
            {/* <div className='social-media-areaf '>
                    sdgskdgjsdkgjkjsdgkj
                </div> */}
            <div className={`menu_items ${sidenav === true ? "active" : ""}`}>
              <ul>
                <li>
                  <Link activeClass="active" to="home" spy={true} smooth={true}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="about" spy={true} smooth={true}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="services" spy={true} smooth={true}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="blog" spy={true} smooth={true}>
                    Solution
                  </Link>
                </li>
                <li>
                  <Link to="contact" spy={true} smooth={true}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
