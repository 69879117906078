import React, { useEffect } from "react";
// import {
//   faChartBar,
//   faCoins,
//   faCopy,
//   faLaptopCode,
//   faMarker,
//   faUserGear,
// } from "@fortawesome/free-solid-svg-icons";
import { FaConnectdevelop } from "@react-icons/all-files/fa/FaConnectdevelop";
import { CgWebsite } from "react-icons/cg";
import { CiMobile3 } from "react-icons/ci";
import { FaLanguage, FaCloud } from "react-icons/fa";
import { GiModernCity } from "react-icons/gi";
import { GrHostMaintenance } from "react-icons/gr";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Services.css";
import SEO from "../SEO/Seo";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Services() {
  // Icons
  //const faLaptopCodeIcon = <FontAwesomeIcon icon={faLaptopCode} />;
  //const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />;
  // const faCopyIcon = <FontAwesomeIcon icon={faCopy} />;
  //const faMarkerIcon = <FontAwesomeIcon icon={faMarker} />;
  //const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />;
  //const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />;

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <div>
        <SEO
          title="Software Development | Technology Solutions | IT Service Provider | Thepacheli"
          description="Empower your business with cutting-edge software, web development, and mobile app solutions from Thepacheli. Partner with our experts for innovation, growth, and success.."
        />
        <div id="services">
          <div className="container">
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="600"
              className="title_headling"
            >
              <h3>We offer various Services and products</h3>
              <p>
                We promote a comprehensive range of software development
                services, catering to a variety of client needs and industry
                sectors. Our core offerings include:
              </p>
            </div>
            <div className="service_wrapper">
              <div data-aos="zoom-in-right" className="service_box">
                <div className="service_icon blue_icon">
                  <FaConnectdevelop />
                </div>
                {/* <h4 className="number">01</h4> */}
                <div className="service_content">
                  <h5>Software Development</h5>
                  <p>
                    Our team specializes in developing custom software
                    applications designed to solve unique business challenges.
                    Leveraging cutting-edge technologies and industry best
                    practices, we build robust, tailored solutions that enable
                    your organization to excel in a competitive market
                  </p>
                  {/* <a href="/" className="read">Read more</a> */}
                </div>
              </div>
              <div data-aos="zoom-in-up" className="service_box">
                <div className="service_icon">
                  <CgWebsite />
                </div>
                {/* <h4 className="number">02</h4> */}
                <div className="service_content">
                  <h5>Website Development</h5>
                  <p>
                    Our team designs and develops professional websites that are
                    visually appealing, user-friendly, and optimized for
                    performance and search engine visibility. We provide
                    User-centric apps that deliver seamless experiences across
                    all platforms.
                  </p>
                  {/* <a href="/" className="read">Read more</a> */}
                </div>
              </div>
              <div data-aos="zoom-in-left" className="service_box">
                <div className="service_icon green_icon">
                  <CiMobile3 />
                </div>
                {/* <h4 className="number">03</h4> */}
                <div className="service_content">
                  <h5>Mobile App Development</h5>
                  <p>
                    We create intuitive and feature-rich mobile applications for
                    iOS and Android platforms, helping businesses engage with
                    their customers on the go.
                  </p>
                  {/* <a href="/" className="read">Read more</a> */}
                </div>
              </div>
              <div data-aos="zoom-in-right" className="service_box">
                <div className="service_icon">
                  <FaLanguage />
                </div>
                {/* <h4 className="number">04</h4> */}
                <div className="service_content">
                  <h5>Localization Framework</h5>
                  <p>
                    Our Localization Framework service is designed to help
                    software development companies like yours seamlessly
                    localize your software, apps, and websites for a global
                    audience.
                  </p>
                  {/* <a href="/" className="read">Read more</a> */}
                </div>
              </div>
              <div data-aos="zoom-in-up" className="service_box">
                <div className="service_icon green_icon">
                  <FaCloud />
                </div>
                {/* <h4 className="number">05</h4> */}
                <div className="service_content">
                  <h5>Cloud Solutions</h5>
                  <p>
                    Unlock the full potential of cloud computing with our
                    innovative cloud solutions, designed to provide scalability,
                    security, and accessibility.
                  </p>
                  {/* <a href="/" className="read">Read more</a> */}
                </div>
              </div>
              <div data-aos="zoom-in-left" className="service_box">
                <div className="service_icon blue_icon">
                  <GiModernCity />
                  <GrHostMaintenance />
                </div>
                {/* <h4 className="number">06</h4> */}
                <div className="service_content">
                  <h5>Modernization & Maintenance</h5>
                  <p>
                    Transform and maintain your legacy systems with our
                    modernization and maintenance services. Our team of experts
                    specializes in upgrading outdated systems, providing website
                    maintenance, and offering support for Content Management
                    Systems (CMS) / (CRM).
                  </p>
                  {/* <a href="/" className="read">Read more</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
