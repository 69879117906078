import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import "./Index.css";

function Index() {
  const [form, setForm] = useState({
    quote_name: "",
    quote_email: "",
    quote_tel: "",
    quote_message: "",
  });
  const [quote_service, setService] = useState("");
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!form.quote_name) {
      formIsValid = false;
      formErrors["quote_name"] = "Name is required.";
    }

    if (!form.quote_email) {
      formIsValid = false;
      formErrors["quote_email"] = "Email is required.";
    }

    if (!form.quote_tel) {
      formIsValid = false;
      formErrors["quote_tel"] = "Phone number is required.";
    }

    if (!quote_service) {
      formIsValid = false;
      formErrors["quote_service"] = "Service is required.";
    }

    setErrors(formErrors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = {};
    for (let input of e.target.elements) {
      if (
        input.tagName === "INPUT" ||
        input.tagName === "SELECT" ||
        input.tagName === "TEXTAREA"
      ) {
        formData[input.name] = input.value;
      }
    }

    if (validateForm()) {
      setLoading(true);
      emailjs
        .send("service_hgd3jle", "template_lb5h538", formData, {
          publicKey: "Bs982XTZMxlM3CQ9d",
        })
        .then(
          (result) => {
            console.log(result.text);
            setSubmitted(true);
            setLoading(false);
            toast.success("Your form has been submitted successfully!");
          },
          (error) => {
            console.log(error.text);
            setLoading(false);
            toast.error("An error occurred while submitting your form.");
          }
        );
    }
  };

  return (
    <div
      className="Indexs"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "60vh",
      }}
    >
      <h1>Get a Quote</h1>
      <ToastContainer />
      {submitted ? (
        <div className="confirmation">
          <h1>Success!</h1>
          <p>Thank you very much for your request.</p>
          <p>
            We appreciate your trust in ThePacheli Technologies & Services. Our
            team will be in touch with you shortly.
          </p>
          <div className="loader"></div>
        </div>
      ) : loading ? (
        <ClipLoader color="#f86c6b" size={150} loading={loading} />
      ) : (
        <form onSubmit={handleSubmit}>
          <label>
            Full name / Organization Name:
            <input
              type="text"
              autoComplete="new-password"
              name="quote_name"
              onChange={handleChange}
              style={{
                marginTop: "10px",
                width: "90%",
                fontSize: "1.4rem",
              }}
            />
            {errors.name && <div className="error-grid">{errors.name}</div>}
          </label>
          <label>
            Email:
            <input
              type="email"
              autoComplete="new-password"
              name="quote_email"
              onChange={handleChange}
              style={{ marginTop: "10px", width: "90%", fontSize: "1.4rem" }}
            />
            {errors.email && <div className="error-grid">{errors.email}</div>}
          </label>
          <label>
            Phone No:
            <input
              type="tel"
              autoComplete="new-password"
              name="quote_tel"
              onChange={handleChange}
              style={{ marginTop: "10px", width: "90%", fontSize: "1.4rem" }}
            />
            {errors.phone && <div className="error-grid">{errors.phone}</div>}
          </label>
          <label>
            Service:
            <select
              name="quote_service"
              value={quote_service}
              onChange={(e) => setService(e.target.value)}
              style={{ marginTop: "10px", width: "96%", fontSize: "1rem" }}
            >
              <option value="">--Please choose an option--</option>
              <option value="App Development">App Development</option>
              <option value="Cloud Solutions">Cloud Solutions</option>
              <option value="Custom Software Development">
                Custom Software Development
              </option>
              <option value="Enterprise Application Integration">
                Enterprise Application Integration
              </option>
              <option value="Localization Framework">
                Localization Service(s)
              </option>
              <option value="Mobile App Development">
                Mobile App Development
              </option>
              <option value="Modernization & Maintenance">
                Modernization & Maintenance
              </option>
              <option value="Mail & Web Hosting">Mail & Web Hosting</option>

              <option value="Website Development">Website Development</option>
              <option value="Other IT Services">Other IT Services</option>
            </select>
            {errors.service && (
              <div className="error-grid">{errors.service}</div>
            )}
          </label>
          <label>
            Tell us more about what you need:
            <textarea
              name="quote_message"
              onChange={handleChange}
              style={{ marginTop: "10px", width: "90%", fontSize: "1.4rem" }}
            />
          </label>

          <input type="submit" value="Request a free Quotation" />
        </form>
      )}
    </div>
  );
}

export default Index;
