import React, { useEffect, useState } from "react";
import {
  faPhone,
  faArrowsToCircle,
  faTruckFast,
  faUserFriends,
  faHighlighter,
  // faBalanceScaleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FaConnectdevelop } from "@react-icons/all-files/fa/FaConnectdevelop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdDifference } from "react-icons/md";
import AboutImage from "../../assets/about/about_50_thepacheli-teambouding-teamwork-teamspirit-joinus-24.jpg";
import "./About.css";
import Modal from "react-modal";
import SEO from "../SEO/Seo";
import AOS from "aos";
import "aos/dist/aos.css";
import MyQuotation from "../Quotation/Index";

export default function About() {
  // Icons
  const faTruckFastIcon = <FontAwesomeIcon icon={faTruckFast} />;
  const faHighlighterIcon = <FontAwesomeIcon icon={faHighlighter} />;
  //const faBalanceScaleLeftIcon = <FontAwesomeIcon icon={faBalanceScaleLeft} />;
  const faArrowsToCircleIcon = <FontAwesomeIcon icon={faArrowsToCircle} />;
  const faUserFriendIcon = <FontAwesomeIcon icon={faUserFriends} />;
  const faPhoneIcon = <FontAwesomeIcon icon={faPhone} />;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <div>
        <SEO
          title="Software Development | Technology Solutions | IT Service Provider | Thepacheli"
          description="Empower your business with cutting-edge software, web development, and mobile app solutions from Thepacheli. Partner with our experts for innovation, growth, and success.."
        />

        <section id="about">
          <div className="container">
            <div
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="600"
              className="title_headling"
            >
              <h3>We empower businesses to thrive in the digital age!</h3>
              <p>
                We are passionate about crafting intelligent and impactful
                software solutions that transcend expectations, leveraging our
                technical expertise,...
              </p>
            </div>
            <div className="about_box_wrapper">
              <div
                data-aos="fade-right"
                data-aos-delay="30"
                className="about_box"
              >
                <div className="about_icon green_icon">{faUserFriendIcon}</div>
                <div className="about_content">
                  <h5>Customer Focus</h5>
                  <p>
                    We understand that your success is our success, putting your
                    needs at the heart of everything we do.
                  </p>
                </div>
              </div>
              <div data-aos="fade-up" data-aos-delay="30" className="about_box">
                <div className="about_icon blue_icon">{faTruckFastIcon}</div>
                <div className="about_content">
                  <h5>Zero-Defect Delivery</h5>
                  <p>
                    Craft software solutions that are groundbreaking, Represents
                    your dedication to delivering flawless and bug-free
                    software.
                  </p>
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="10"
                className="about_box"
              >
                <div className="about_icon yellow_icon">
                  <FaConnectdevelop />
                </div>
                <div className="about_content">
                  <h5>Development</h5>
                  <p>
                    Develop innovative software solutions that address specific
                    industry challenges and drive digital transformation for our
                    clients.
                  </p>
                </div>
              </div>
            </div>

            <div className="about_box_details">
              <div data-aos="zoom-in-right" className="about_col">
                <div className="about_image">
                  <img src={AboutImage} alt="about" className="about_main" />
                </div>
                <div className="img_info__box">
                  <p>
                    We strive to your Satisfaction and Excellence! Your success
                    is our passion. Partner with us today to unlock your digital
                    potential. We deliver groundbreaking software with a
                    client-centric approach.{" "}
                  </p>
                  <p className="badge bg-primary text-light">
                    <b>Start Your Digital Transformation Today,</b>
                  </p>
                  <div className="btn_wrapper">
                    <button
                      className="btn"
                      style={{ width: 180 }}
                      onClick={openModal}
                    >
                      Get a quote
                    </button>
                  </div>

                  <a href="/">
                    {faPhoneIcon} <span>+27-(73)-449-9868</span>
                  </a>
                </div>
              </div>
              <div
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="600"
                className="about_col more_space"
              >
                <h3>
                  Empowering businesses to thrive through innovative solutions
                </h3>
                <p>
                  Helping you achieve your business goals with expert guidance
                  and cutting-edge technology. Partnering with you to transform
                  your business and exceed expectations.
                </p>

                <div
                  data-aos="fade-left"
                  data-aos-delay="30"
                  className="grid_info"
                >
                  <div className="icon yellow_icon">{faArrowsToCircleIcon}</div>
                  <div className="detail">
                    <h4>Focusing on client impact</h4>
                    <p>
                      Unlocking your digital potential and driving measurable
                      results.{" "}
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-delay="30"
                  className="grid_info"
                >
                  <div className="icon green_icon">{faHighlighterIcon}</div>
                  <div className="detail">
                    <h4>Highlighting specific values</h4>
                    <p>
                      Delivering exceptional results with integrity,
                      collaboration, and a client-centric approach.
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-delay="30"
                  className="grid_info"
                >
                  <div className="icon blue_icon">
                    <MdDifference />
                  </div>
                  <div className="detail">
                    <h4>Emphasizing unique differentiation</h4>
                    <p>
                      Leveraging the power of technology to deliver agile and
                      scalable solutions. Investing in your success with ongoing
                      support and a long-term partnership
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        ariaHideApp={false}
      >
        <button
          onClick={() => setModalIsOpen(false)}
          style={{ position: "absolute", right: 15, top: 15 }}
          aria-label="Close Modal"
        >
          X
        </button>
        <MyQuotation />
      </Modal>
    </>
  );
}
